import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
    root: {
        position: 'relative',
        borderRadius: 4
    },
    error: {
        background: '#E2E8E9',
        backgroundBlendMode: 'multiply'
    },
    list: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        background: '#FFFFFF',
        borderRadius: 16,
        padding: '12px 0px',
        boxShadow: '0px 10px 40px 0px #C8D4DC59',
        zIndex: theme.zIndex.tooltip
    },
    option: {
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '16px',
        color: '#282D31',
        '&:not(:hover)[data-focus]': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    matched: {
        color: '#8385A3'
    },
    highlight: {
        fontWeight: 700,
        color: '#282D31'
    }
}));
