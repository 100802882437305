import { makeStyles } from 'tss-react/mui';

import { inputClasses } from '@mui/material';

export default makeStyles()({
    input: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        color: '#282D31',
        caretColor: '#FF6600',
        borderRadius: 4,
        padding: '0 4px',
        minWidth: 50,
        [`&.${inputClasses.error}`]: {
            background: '#E2E8E9',
            backgroundBlendMode: 'multiply'
        }
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    icon: {
        width: 20,
        height: 20
    },
    text: {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: '150%'
    }
});
